// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-components-arrow-down-js": () => import("./../src/pages/components/ArrowDown.js" /* webpackChunkName: "component---src-pages-components-arrow-down-js" */),
  "component---src-pages-components-footer-js": () => import("./../src/pages/components/Footer.js" /* webpackChunkName: "component---src-pages-components-footer-js" */),
  "component---src-pages-components-image-figure-js": () => import("./../src/pages/components/ImageFigure.js" /* webpackChunkName: "component---src-pages-components-image-figure-js" */),
  "component---src-pages-components-layout-js": () => import("./../src/pages/components/Layout.js" /* webpackChunkName: "component---src-pages-components-layout-js" */),
  "component---src-pages-components-menu-js": () => import("./../src/pages/components/Menu.js" /* webpackChunkName: "component---src-pages-components-menu-js" */),
  "component---src-pages-components-project-item-js": () => import("./../src/pages/components/ProjectItem.js" /* webpackChunkName: "component---src-pages-components-project-item-js" */),
  "component---src-pages-components-stocks-js": () => import("./../src/pages/components/stocks.js" /* webpackChunkName: "component---src-pages-components-stocks-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-files-js": () => import("./../src/pages/my-files.js" /* webpackChunkName: "component---src-pages-my-files-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-page-template-js": () => import("./../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

